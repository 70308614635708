import {
    project1,
    project2,
    project3,
    project4,
    project5,
    project6,
    project7,
    project8,
    project9,
    project10,
    project11,
    project12,
    project13,
    project14,
    project15,
    project16,
    project17,
    project18,
    project19,
    project20,
    project21,
    project22,
    project23,
    project24,
    project25,
    project26,
    project27,
    project28,
    project29,
    project30,
    project31,
    project32,
    project33,
    project34,
    project35,
    project36,
    project37,
    project38,
    project39,
    project40,
    project41,
    project42,
    project43,
    project44,
    project45,
    project46,
    project401,
    project402,
    project403,
    project404,
    project405,
    project406,
    project407,
    project408,
    project409,
    project410
  } from "../../assets/index";
  
  const projects = [
    {
      title: "Car Marketplace App",
      des: "Technology: MEAN (MongoDB, Express, Angular, Node.js)",
      src: project46,
      github_link:
        "https://github.com/atokic/MEAN-Car-Marketplace-App",
      site_link: "",
      category: "development",
    },
    {
      title: "Dynamic video stream",
      des: "Technology: Node.js",
      src: project1,
      github_link:
        "https://github.com/atokic/Web-service-for-dynamic-video-streaming",
      site_link: "",
      category: "development",
    },
    {
      title: "Car Management System",
      des: "Technology: Angular, .NET Core, MySQL",
      src: project2,
      github_link: "https://github.com/atokic/Car-Managment-System",
      site_link: "",
      category: "development",
    },
    {
      title: "YOLO detector",
      des: "Technology: Python",
      src: project3,
      github_link: "https://github.com/atokic/YOLOv4-Custom-Object-Detecion",
      site_link: "",
      category: "development",
    },
    {
      title: "Chip-Top.com",
      des: "Technology: Wordpress",
      src: project35,
      github_link: "",
      site_link: "https://chip-top.com/",
      category: "webDesign",
    },
    {
      title: "Svezabazene.hr",
      des: "Technology: Wordpress",
      src: project4,
      github_link: "",
      site_link: "https://svezabazene.hr/",
      category: "webDesign",
    },
    {
      title: "Estetika dek. kamen",
      des: "Technology: Wordpress",
      src: project5,
      github_link: "",
      site_link: "https://estetika-dekorativni-kamen.com/",
      category: "webDesign",
    },
    {
      title: "Bazenskatehnika.hr",
      des: "Technology: Wordpress",
      src: project6,
      github_link: "",
      site_link: "https://bazenskatehnika.hr/",
      category: "webDesign",
    },
    {
      title: "FireBody Say Yes",
      des: "Technology: Wordpress",
      src: project16,
      github_link: "",
      site_link: "https://firebody-say-yes.com/",
      category: "webDesign",
    },
    {
      title: "Mikis Food & Bar",
      des: "Technology: Wordpress",
      src: project17,
      github_link: "",
      site_link: "http://mikis-food.com/",
      category: "webDesign",
    },
    {
      title: "Hair Style",
      des: "Technology: Wordpress",
      src: project19,
      github_link: "",
      site_link: "https://hairstyle-sinj.com/",
      category: "webDesign",
    },
    {
      title: "MaK boutique",
      des: "Technology: Wordpress",
      src: project18,
      github_link: "",
      site_link: "https://mak-boutique.com/",
      category: "webDesign",
    },
    {
      title: "Lybsom massage",
      des: "Technology: Wordpress",
      src: project21,
      github_link: "",
      site_link: "https://lybsom-massage.com/",
      category: "webDesign",
    },
    {
      title: "Pčelarstvo Dolić",
      des: "Technology: Wordpress",
      src: project36,
      github_link: "",
      site_link: "https://pcelarstvo-dolic.com/",
      category: "webDesign",
    },
    {
      title: "React Calculator",
      des: "Technology: React",
      src: project7,
      github_link: "https://github.com/atokic/ReactJS_Calculator",
      site_link: "https://reactjs-simple-calculator-app.netlify.app/",
      category: "development",
    },
    {
      title: "React Portfolio",
      des: "Technology: React, Tailwind",
      src: project20,
      github_link: "https://github.com/atokic/ReactJS_portfolio/",
      site_link: "https://atokic.netlify.app/",
      category: "development",
    },
    {
      title: "Booking App",
      des: "Technology: MERN",
      src: project8,
      github_link: "https://github.com/atokic/MERN-Booking-App",
      site_link: "",
      category: "development",
    },
    {
      title: "React SaSS Portfolio",
      des: "Technology: React",
      src: project9,
      github_link: "https://github.com/atokic/ReactJS-SaSS-Portfolio",
      site_link: "https://portfolio-ante.netlify.app/",
      category: "development",
    },
    {
      title: "Youtube Downloader",
      des: "Technology: Python",
      src: project10,
      github_link:
        "https://github.com/atokic/Python-Youtube-Downloader-and-Converter",
      site_link: "",
      category: "development",
    },
    {
      title: "MERN ToDo List App",
      des: "Technology: MERN",
      src: project11,
      github_link: "https://github.com/atokic/MERN-ToDo-List",
      site_link: "",
      category: "development",
    },
    {
      title: "React Weather App",
      des: "Technology: React",
      src: project12,
      github_link: "https://github.com/atokic/ReactJS-Weather-App",
      site_link: "https://reactjs-simple-weather.netlify.app/",
      category: "development",
    },
    {
      title: "React Random Quote",
      des: "Technology: React",
      src: project13,
      github_link: "https://github.com/atokic/ReactJS-Random-Quote-App",
      site_link: "https://random-qoute-app.netlify.app/",
      category: "development",
    },
    {
      title: "React Tic Tac Toe Game",
      des: "Technology: React",
      src: project14,
      github_link: "https://github.com/atokic/ReactJS-Tic-Tac-Toe-Game",
      site_link: "https://reactjs-game-tic-tac-toe.netlify.app/",
      category: "development",
    },
    {
      title: "React Restaurant",
      des: "Technology: React",
      src: project15,
      github_link: "https://github.com/atokic/ReactJS-Restaurant-Website",
      site_link: "https://reactjs-restaurant-website.netlify.app/",
      category: "development",
    },
    {
      title: "F1",
      des: "Technology: React Native",
      src: project22,
      github_link: "https://github.com/atokic/F1-React-Native-App",
      site_link: "",
      category: "development",
    },
    {
      title: "Chuck Norris Joke Backend App",
      des: "Technology: TypeScript, REST, Node js, Express, MongoDB",
      src: project23,
      github_link: "https://github.com/atokic/Chuck_Norris_Joke_App",
      site_link: "",
      category: "development",
    },
    {
      title: "Project Tracking Software",
      des: "Technology: MERN",
      src: project37,
      github_link: "https://github.com/atokic/Project-Tracking-Software/",
      site_link: "",
      category: "development",
    },
    {
      title: "React Essential",
      des: "Technology: React",
      src: project24,
      github_link: "https://github.com/atokic/React-Essentials",
      site_link: "https://react-essentials-app.netlify.app/",
      category: "development",
    },
    {
      title: "React Tic-Tac-Toe",
      des: "Technology: React",
      src: project25,
      github_link: "https://github.com/atokic/React-Tic-Tac-Toe",
      site_link: "https://tic-tac-toe-app-game-react.netlify.app/",
      category: "development",
    },
    {
      title: "React Investment Calculator",
      des: "Technology: React",
      src: project26,
      github_link: "https://github.com/atokic/Investment-Calculator",
      site_link: "https://investment-calculator-app-react.netlify.app/",
      category: "development",
    },
    {
      title: "React Styling",
      des: "Technology: React",
      src: project27,
      github_link: "https://github.com/atokic/React-Styling",
      site_link: "https://react-styling.netlify.app/",
      category: "development",
    },
    {
      title: "React Timers Challenge App",
      des: "Technology: React",
      src: project28,
      github_link: "https://github.com/atokic/Timers-Challenge-App",
      site_link: "https://timers-challenge-app.netlify.app/",
      category: "development",
    },
    {
      title: "React Project Management App",
      des: "Technology: React",
      src: project29,
      github_link: "https://github.com/atokic/Project-Management-App",
      site_link: "https://project-management-application-react.netlify.app/",
      category: "development",
    },
    {
      title: "React Advanced State Management",
      des: "Technology: React",
      src: project30,
      github_link: "https://github.com/atokic/React-Advanced-State-Management",
      site_link: "https://advanced-state-management-react.netlify.app/",
      category: "development",
    },
    {
      title: "React Place Picker With Local Storage",
      des: "Technology: React",
      src: project31,
      github_link: "https://github.com/atokic/Place-Picker",
      site_link: "https://react-place-picker.netlify.app/",
      category: "development",
    },
    {
      title: "React Quiz App",
      des: "Technology: React",
      src: project32,
      github_link: "https://github.com/atokic/React-Quiz-App",
      site_link: "https://quiz-game-app-react.netlify.app/",
      category: "development",
    },
    {
      title: "React behind the scenes",
      des: "Technology: React",
      src: project33,
      github_link: "https://github.com/atokic/React---Behind-The-Scenes",
      site_link: "https://react-behind-the-scenes.netlify.app/",
      category: "development",
    },
    {
      title: "React Class-based Components",
      des: "Technology: React",
      src: project34,
      github_link: "https://github.com/atokic/React-Class-based-Components",
      site_link: "https://react-class-based-components-example.netlify.app/",
      category: "development",
    },
    {
      title: "React Place Picker With Backend",
      des: "Technology: React",
      src: project38,
      github_link: "https://github.com/atokic/Place-Picker-With-Backend",
      site_link: "",
      category: "development",
    },
    {
      title: "React Place Picker With Custom Hook",
      des: "Technology: React",
      src: project39,
      github_link:
        "https://github.com/atokic/React-Place-Picker-With-Custom-Hook",
      site_link: "",
      category: "development",
    },
    {
      title: "React Place Picker With Custom Hook",
      des: "Technology: React",
      src: project40,
      github_link: "https://github.com/atokic/React-Forms",
      site_link: "https://react-forms-inputs.netlify.app/",
      category: "development",
    },
    {
      title: "React Food Order App",
      des: "Technology: React",
      src: project41,
      github_link: "https://github.com/atokic/React-Food-Order-App",
      site_link: "",
      category: "development",
    },
    {
      title: "React Redux Basics",
      des: "Technology: React",
      src: project42,
      github_link: "https://github.com/atokic/React-Redux-Basics",
      site_link: "https://react-redux-basics.netlify.app/",
      category: "development",
    },
    {
      title: "React Advanced Redux",
      des: "Technology: React",
      src: project43,
      github_link: "https://github.com/atokic/React-Advanced-Redux",
      site_link: "https://react-advanced-redux.netlify.app/",
      category: "development",
    },
    {
      title: "React Routing Basics",
      des: "Technology: React",
      src: project44,
      github_link: "https://github.com/atokic/React-Routing-Basics",
      site_link: "https://react-routing-basics.netlify.app/",
      category: "development",
    },
    {
      title: "React Advanced Routing",
      des: "Technology: React",
      src: project45,
      github_link: "https://github.com/atokic/React-Advanced-Routing-App",
      site_link: "",
      category: "development",
    },
    {
      title: "Caffe bar Džokej Story/Post",
      des: "Technology: Photoshop",
      src: project401,
      github_link: "",
      site_link: "https://www.instagram.com/caffe_bar_dzokej/",
      category: "graphicDesign",
    },
    {
      title: "Caffe bar Džokej Story/Post",
      des: "Technology: Photoshop",
      src: project402,
      github_link: "",
      site_link: "https://www.instagram.com/caffe_bar_dzokej/",
      category: "graphicDesign",
    },
    {
      title: "Caffe bar Džokej Story/Post",
      des: "Technology: Photoshop",
      src: project403,
      github_link: "",
      site_link: "https://www.instagram.com/caffe_bar_dzokej/",
      category: "graphicDesign",
    },
    {
      title: "Caffe bar Džokej Story/Post",
      des: "Technology: Photoshop",
      src: project404,
      github_link: "",
      site_link: "https://www.instagram.com/caffe_bar_dzokej/",
      category: "graphicDesign",
    },
    {
      title: "Caffe bar Džokej Story/Post",
      des: "Technology: Photoshop",
      src: project405,
      github_link: "",
      site_link: "https://www.instagram.com/caffe_bar_dzokej/",
      category: "graphicDesign",
    },
    {
      title: "Ragbi Klub Sinj Story/Post",
      des: "Technology: Photoshop",
      src: project406,
      github_link: "",
      site_link: "https://www.facebook.com/p/Ragbi-Klub-Sinj-100057331253471/?locale=hr_HR",
      category: "graphicDesign",
    },
    {
      title: "Ragbi Klub Sinj Story/Post",
      des: "Technology: Photoshop",
      src: project407,
      github_link: "",
      site_link: "https://www.facebook.com/p/Ragbi-Klub-Sinj-100057331253471/?locale=hr_HR",
      category: "graphicDesign",
    },  
    {
      title: "Estetika dekorativni kamen Story/Post",
      des: "Technology: Photoshop",
      src: project408,
      github_link: "",
      site_link: "https://www.facebook.com/estetika.dek.kamen",
      category: "graphicDesign",
    },  
    {
      title: "Ragbi Klub Sinj Story/Post",
      des: "Technology: Photoshop",
      src: project409,
      github_link: "",
      site_link: "https://www.facebook.com/p/Ragbi-Klub-Sinj-100057331253471/?locale=hr_HR",
      category: "graphicDesign",
    },   
    {
      title: "Estetika dekorativni kamen Story/Post",
      des: "Technology: Photoshop",
      src: project410,
      github_link: "",
      site_link: "https://www.facebook.com/estetika.dek.kamen",
      category: "graphicDesign",
    },  
  ];
  
  export default projects;
  